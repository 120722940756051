
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import BarChart from "@/comp/Charts/BarChart.vue";
import LineChart from "@/comp/Charts/LineChart.vue";
import { getBloggerLogChart, getBloggerLogHourList } from "@/api/request/bigdata";

//组件
@Component({
  name: "BloggerBubblesLog",
  components: {
    BarChart,
    LineChart,
  },
})

//代码
export default class extends Vue {
  //定义变量
  private total: number = 0; //总数
  private list: any[] = []; //列表
  private currentIndex = "0"; //索引
  private dateList: any[] = []; //列表
  private userList: any[] = []; //列表
  private listLoading: boolean = false; // 列表加载

  //请求数据
  private listQuery: any = {
    date: "",
  };

  //创建调用
  created() {
    //数据赋值
    this.listQuery.date = DTCls.getCurDateTimeYMD();

    //获取列表
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getBloggerLogChart(this.listQuery);

    //数据赋值
    this.list = data;
    this.dateList = [];
    this.userList = [];
    for (var i: number = 0; i < data.length; i++) {
      this.userList[i] = data[i].num;
      this.dateList[i] = data[i].date_time_hour + "点";
    }

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //获取列表
    this.getList();
  }

  //改变类型
  private changeTab(val: any): void {
    //数据赋值
    this.currentIndex = val.index;
  }

  //点击索引
  private indexClick(index: any): void {
    //数据赋值
    this.detailQuery = {
      date: this.list[index].date,
      hour: this.list[index].date_time_hour,
    };

    //显示列表
    this.dialogVisible = true;

    //获取数据
    this.getHourList();
  }

  //-------------------------- 详情 --------------------------

  //定义变量
  private detailList: any[] = [];
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //请求参数
  private detailQuery: {
    date: string;
    hour: string;
  } = {
    date: "",
    hour: "",
  };

  //关闭按钮
  private btnCancel() {
    //隐藏界面
    this.dialogVisible = false;

    //清空数据
    this.detailList = [];
  }

  //获取小时数据
  private async getHourList() {
    //显示等待
    this.dialogLoading = true;

    //获取数据
    const { data } = await getBloggerLogHourList(this.detailQuery);

    //数据赋值
    this.detailList = data;

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 0.5 * 1000);
  }
}
